exports.components = {
  "component---src-components-template-contact-page-template-tsx": () => import("./../../../src/components/template/contactPageTemplate.tsx" /* webpackChunkName: "component---src-components-template-contact-page-template-tsx" */),
  "component---src-components-template-country-service-page-template-tsx": () => import("./../../../src/components/template/countryServicePageTemplate.tsx" /* webpackChunkName: "component---src-components-template-country-service-page-template-tsx" */),
  "component---src-components-template-country-template-tsx": () => import("./../../../src/components/template/countryTemplate.tsx" /* webpackChunkName: "component---src-components-template-country-template-tsx" */),
  "component---src-components-template-error-page-template-tsx": () => import("./../../../src/components/template/errorPageTemplate.tsx" /* webpackChunkName: "component---src-components-template-error-page-template-tsx" */),
  "component---src-components-template-global-reach-home-page-template-tsx": () => import("./../../../src/components/template/globalReachHomePageTemplate.tsx" /* webpackChunkName: "component---src-components-template-global-reach-home-page-template-tsx" */),
  "component---src-components-template-home-page-template-tsx": () => import("./../../../src/components/template/homePageTemplate.tsx" /* webpackChunkName: "component---src-components-template-home-page-template-tsx" */),
  "component---src-components-template-page-template-tsx": () => import("./../../../src/components/template/pageTemplate.tsx" /* webpackChunkName: "component---src-components-template-page-template-tsx" */),
  "component---src-components-template-policy-page-template-tsx": () => import("./../../../src/components/template/policyPageTemplate.tsx" /* webpackChunkName: "component---src-components-template-policy-page-template-tsx" */),
  "component---src-components-template-resource-page-template-tsx": () => import("./../../../src/components/template/resourcePageTemplate.tsx" /* webpackChunkName: "component---src-components-template-resource-page-template-tsx" */),
  "component---src-components-template-resources-home-page-tsx": () => import("./../../../src/components/template/resourcesHomePage.tsx" /* webpackChunkName: "component---src-components-template-resources-home-page-tsx" */),
  "component---src-components-template-resources-index-template-tsx": () => import("./../../../src/components/template/resourcesIndexTemplate.tsx" /* webpackChunkName: "component---src-components-template-resources-index-template-tsx" */),
  "component---src-components-template-resources-landing-page-tsx": () => import("./../../../src/components/template/resourcesLandingPage.tsx" /* webpackChunkName: "component---src-components-template-resources-landing-page-tsx" */),
  "component---src-components-template-thank-you-template-tsx": () => import("./../../../src/components/template/thankYouTemplate.tsx" /* webpackChunkName: "component---src-components-template-thank-you-template-tsx" */),
  "component---src-pages-zh-hans-联系我们-tsx": () => import("./../../../src/pages/zh-hans/联系我们.tsx" /* webpackChunkName: "component---src-pages-zh-hans-联系我们-tsx" */),
  "component---src-pages-zh-interact-contact-tsx": () => import("./../../../src/pages/zh/interact/contact.tsx" /* webpackChunkName: "component---src-pages-zh-interact-contact-tsx" */)
}

