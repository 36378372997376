import React, { createContext, useState } from "react";

export const AppContext: any = createContext("");

export const AppProvider = ({ children }: any) => {
  const [utmUrlMain, setUtmUrlMain] = useState<any>("");

  const updateUtm = (utmUrl: string) => {
    setUtmUrlMain(utmUrl);
  };

  return (
    <AppContext.Provider
      value={{
        utmUrlMain,
        updateUtm
      }}
    >
      {children}
    </AppContext.Provider>
  );
};