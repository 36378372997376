module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon-144x.png","icons":[{"src":"src/images/favicon-144x.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicon-144x.png","sizes":"144x144","type":"image/png"},{"src":"src/images/favicon-48x.png","sizes":"48x48","type":"image/png"}],"include_favicon":false,"start_url":"/","name":"Safeguard Global","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"b20ef78574e30d782a8b3fc67dd3f105"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T8F347","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"siteVersion":"newSite"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
